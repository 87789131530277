input:checked ~ .checkmark {
  background-color: #2a3390;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
input:checked ~ .checkmark:after {
  display: block;
}
