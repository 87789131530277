@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Raleway";
  src: local("RalewayLight"),
    url("./assets/fonts/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Raleway";
  src: local("RalewayRegular"),
    url("./assets/fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Raleway";
  src: local("RalewayMedium"),
    url("./assets/fonts/Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Raleway";
  src: local("RalewaySemiBold"),
    url("./assets/fonts/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Raleway";
  src: local("RalewayBold"),
    url("./assets/fonts/Raleway-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Raleway";
  src: local("RalewayExtraBold"),
    url("./assets/fonts/Raleway-ExtraBold.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Calibri";
  src: url("./assets/fonts/Calibri.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Times New Roman";
  src: url("./assets/fonts/times-new-roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.root {
  display: flex;
}

.tooltip:hover span {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
::-webkit-scrollbar {
  width: 6px;
  /* display: none; */
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f2f2f2;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.dark ::-webkit-scrollbar-thumb {
  background: #1b2d44;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f2f2f2;
}
.dark ::-webkit-scrollbar-thumb:hover {
  background: #1b2d44;
}

@media screen and (min-width: 1280px) {
  .card {
    max-height: calc(100vh - 272px);
  }
}
@media screen and (max-width: 2100px) {
  .root {
    justify-content: space-between;
  }
}

.datepicker-range-select div:nth-child(3) {
  top: 39px;
}
.datepicker div:nth-child(3) {
  top: 53px;
}
