.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: loader 1s linear infinite;
}

span:nth-child(2) {
  animation-delay: 0.1s;
}
span:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes loader {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.4;
  }
}
