.fc-toolbar-chunk div {
  display: flex;
  gap: 14px;
  align-items: center;
}

.fc-toolbar-chunk div h2 {
  font-size: 16px !important;
  font-family: "Raleway";
  font-weight: bold;
  color: black;
}

.fc-toolbar-chunk div .fc-button,
.fc-toolbar-chunk div .fc-button:hover,
.fc-toolbar-chunk div .fc-button:focus,
.fc-toolbar-chunk div .fc-button:active,
.fc-toolbar-chunk div .fc-button:active:focus {
  background-color: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
}

.fc-toolbar-chunk div .fc-button span {
  color: black;
}
.fc-toolbar-chunk div .fc-prev-button span::before {
  content: "\2190";
}
.fc-toolbar-chunk div .fc-next-button span::before {
  content: "\2192";
}

.fc-scrollgrid-sync-inner {
  padding-top: 15px;
  padding-bottom: 15px;
}
.fc-scrollgrid-sync-inner a.fc-col-header-cell-cushion {
  font-size: 14px;
  color: #28282c;
  font-family: "Raleway";
  font-weight: bold;
}
.fc-daygrid-day-number {
  font-size: 1rem;
  font-family: "Raleway";
  color: #28282c;
  line-height: 23px;
  font-weight: bold;
}
.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  padding-top: 0;
  padding-bottom: 0px;
}

a.fc-event.fc-event-start.fc-event-end.fc-daygrid-event.fc-daygrid-block-event.fc-h-event {
  border: none;
  background-color: #f7f7f7;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding-left: 16px;
  margin-top: 20px;
}
a.fc-event.fc-event-start.fc-event-end.fc-daygrid-event.fc-daygrid-block-event.fc-h-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 10px;
  background-color: inherit;
}

.fc-event-title {
  color: black;
  font-family: "Raleway";
  font-size: 1rem;
  font-weight: bold;
}

.fc-daygrid-day {
  height: 90px;
}
.fc-view-harness.fc-view-harness-active {
  height: 327.741px !important;
}
.dark {
  background-color: #1b2d44;
}

.dark .fc-toolbar-chunk div h2 {
  color: white;
}
.dark .fc-toolbar-chunk div .fc-button span {
  color: white;
}
.dark .fc-scrollgrid-sync-inner a.fc-col-header-cell-cushion {
  color: white;
}

.dark .fc-daygrid-day-number {
  color: white;
}
.dark
  a.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-daygrid-event.fc-daygrid-block-event.fc-h-event {
  color: white;
}
.dark .fc .fc-view-harness {
  background-color: #1b2d44;
}
